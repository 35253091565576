<template>
  <b-row v-if="variant === 'danger'" :id="'tt-license-warning-' + license.id" class="entry-danger"
         @mouseenter="hover = true" @mouseleave="hover = false">
    <b-col class="text-left pl-1">
      <span class="text-white">{{ license.license.software.displayname }}</span>
    </b-col>
    <b-tooltip :target="'tt-license-warning-' + license.id">Offene Lizenzwarnung</b-tooltip>
    <b-col class="text-right pr-1">
      <div v-if="hover">
        <b-button-group class="button-group">
          <b-button size="sm" variant="primary" class="p-0 border-0 button-group-icon" id="tt-comment" @click="comment">
            <b-icon-clipboard-check/>
          </b-button>
          <b-tooltip target="tt-comment" triggers="hover">Kommentar hinzufügen</b-tooltip>
        </b-button-group>
      </div>
    </b-col>
  </b-row>
  <b-row v-else-if="variant === 'warning'" :id="'tt-license-comment-' + license.id"
         :class="['entry-warning', {'border-success' : license.comment_verified}]">
    <b-col cols="12" class="text-left pl-1">
      <span class="text-dark">{{ license.license.software.displayname }}</span>
    </b-col>
    <b-tooltip :target="'tt-license-comment-' + license.id">
      <span v-if="license.comment_verified">Kommentierte und verifizierte Lizenzwarnung</span>
      <span v-else>Kommentierte Lizenzwarnung</span>
    </b-tooltip>
  </b-row>
  <b-row v-else-if="variant === 'success'" :id="'tt-license-' + license.id" class="entry-success">
    <b-col cols="12" class="text-left pl-1">
      <span class="text-white">{{ license.license.software.displayname }}</span>
    </b-col>
    <b-tooltip :target="'tt-license-' + license.id">Installierte Lizenz</b-tooltip>
  </b-row>
</template>

<script>
export default {
  name: "LicenseEntry",
  props: ['license', 'hostname', 'variant'],
  data() {
    return {
      hover: false
    }
  },
  methods: {
    comment() {
      this.$emit('comment', this.license)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/Licenses.scss';
@import '../../../../assets/styles/details/Entries.scss';
</style>
