<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body :class="[data ? ['tile-body', 'pb-2'] : ['tile-expand-content', 'py-2'], 'p-0']">
        <b-container fluid v-if="data">
          <div v-for="entry in data" :key="entry.id" class="mt-1">
            <VirusEntry :virus="entry" @virus-modal="openModal"/>
          </div>
          <b-row>
            <router-link :to="{ name: 'VirusList', query: {site: shortname, hostname: hostname}}"
                         class="text-center clickable more-data expand-hover rounded mt-1 col router-text">
              Zeige alle {{ count }} Virenfunde...
            </router-link>
          </b-row>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Daten vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
    <VirusModal :title="modalTitle" :virus="virus" :type="type" @refresh="refresh"/>
  </Tile>
</template>

<script>
import Tile from "@/components/details/Tile";
import VirusModal from "@/components/lists/virusList/VirusModal.vue";
import VirusEntry from "@/components/details/computerDetails/entries/VirusEntry";

export default {
  name: "TileViruses",
  props: ['title', 'data', 'count', 'hostname'],
  components: {
    Tile,
    VirusModal,
    VirusEntry
  },
  data() {
    return {
      virus: null,
      type: null,
      modalTitle: null
    }
  },
  methods: {
    openModal({ virus, type}) {
      if(type === 'acknowledge') {
        this.modalTitle = 'Virus zur Kenntnis nehmen'
        this.type = type
      }
      if(type === 'allowTemp') {
        this.modalTitle = 'Virus zu der temporären Allowlist hinzufügen'
        this.type = type
      }
      this.virus = virus
      this.$bvModal.show('virusModal')
    },
    refresh() {
      this.$emit('refresh')
    }
  },
  computed: {
    shortname() {
      return this.hostname.split('-')[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles.scss';
</style>
