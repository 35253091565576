<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body :class="[data ? ['tile-body', 'pb-2'] : ['tile-expand-content', 'py-2'], 'p-0']">
        <b-container fluid v-if="data">
          <div v-for="entry in data" :key="entry.id" class="mt-1">
            <ProcessEntry :process="entry" @acknowledge="acknowledge" @allow-temp="openModal"/>
          </div>
          <b-row>
            <router-link :to="{ name: 'ProcessList', query: {site: shortname, hostname: hostname}}"
                         class="text-center clickable more-data expand-hover rounded mt-1 col router-text">
              Zeige alle {{ count }} Prozessfunde...
            </router-link>
          </b-row>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Daten vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
    <ProcessModal :process="process" @refresh="refresh"/>
  </Tile>
</template>

<script>
import {putRequest} from "@/modules/requests";
import Tile from "@/components/details/Tile";
import ProcessModal from "@/components/lists/processList/ProcessModal.vue";
import ProcessEntry from "@/components/details/computerDetails/entries/ProcessEntry";

export default {
  name: "TileProcesses",
  props: ['title', 'data', 'count', 'hostname'],
  components: {
    Tile,
    ProcessModal,
    ProcessEntry
  },
  data() {
    return {
      process: null
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    openModal(process) {
      this.process = process
      this.$bvModal.show('processModal')
    },
    async acknowledge(process) {
      await putRequest('processes/acknowledge/' + process.id, null, this)
          .then(() => {
            this.$emit('refresh')
          })
    }
  },
  computed: {
    shortname() {
      return this.hostname.split('-')[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles.scss';
</style>
