<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body :class="[data ? ['tile-body', 'pb-2'] : ['tile-expand-content', 'py-2'], 'p-0']">
        <b-container fluid v-if="data">
          <div v-for="entry in data" :key="entry.id" class="mt-1">
            <b-row v-if="entry.status === 'pending'" :id="'tt-task-d-pending-' + entry.id"
                   class="tile-expand-task-pending">
              <b-col class="p-0">
                <b-progress :max="1" striped>
                  <b-progress-bar :value="1" class="text-white text-border px-1 tile-expand-task-progress-pending">
                    <span v-if="entry.type === 'install'" class="text-left progress-bar-fix-action-history">
                      {{ entry.displayname }} - Installation
                    </span>
                    <span v-else-if="entry.type === 'deinstall'" class="text-left progress-bar-fix-action-history">
                      {{ entry.displayname }} - Deinstallation
                    </span>
                    <span v-else-if="entry.type === 'sync'" class="text-left progress-bar-fix-action-history">
                      {{ entry.displayname }} - Sync
                    </span>
                    <span v-else-if="entry.type === 'hostname'" class="text-left progress-bar-fix-action-history">
                      {{ entry.displayname }} - {{ entry.command_parameter }}
                    </span>
                    <span v-else class="text-left progress-bar-fix-action-history">{{ entry.displayname }}</span>
                    <span class="text-right"><b-icon-stopwatch-fill
                        class="mr-1"/>{{ entry.time_execute_scheduled | datetime }}</span>
                  </b-progress-bar>
                  <b-tooltip :target="'tt-task-d-pending-' + entry.id" triggers="hover">
                    Gesetzt von {{ entry.issuer }}
                  </b-tooltip>
                </b-progress>
              </b-col>
            </b-row>
            <b-row v-if="entry.status === 'running'" :id="'tt-task-d-running-' + entry.id"
                   class="tile-expand-task-running">
              <b-col class="p-0">
                <b-progress :max="entry.progress_max" animated class="tile-expand-task-progress-running">
                  <b-progress-bar :value="entry.progress_current" class="text-white text-border pl-1">
                   <span v-if="entry.type === 'install'" class="text-left progress-bar-fix-action-history">
                      {{ entry.displayname }} - Installation
                    </span>
                    <span v-else-if="entry.type === 'deinstall'" class="text-left progress-bar-fix-action-history">
                      {{ entry.displayname }} - Deinstallation
                    </span>
                    <span v-else-if="entry.type === 'sync'" class="text-left progress-bar-fix-action-history">
                      {{ entry.displayname }} - Sync
                    </span>
                    <span v-else-if="entry.type === 'hostname'" class="text-left progress-bar-fix-action-history">
                      {{ entry.displayname }} - {{ entry.command_parameter }}
                    </span>
                    <span v-else class="text-left progress-bar-fix-action-history">{{ entry.displayname }}</span>
                  </b-progress-bar>
                  <b-tooltip :target="'tt-task-d-running-' + entry.id" triggers="hover">
                    Gesetzt von {{ entry.issuer }}
                  </b-tooltip>
                </b-progress>
              </b-col>
            </b-row>
            <b-row v-if="entry.status === 'finished'" :id="'tt-task-d-finished-' + entry.id"
                   class="tile-expand-task-finished">
              <b-col class="text-left pl-1">
                <span v-if="entry.type === 'install'">{{ entry.displayname }} - Installation</span>
                <span v-else-if="entry.type === 'deinstall'">{{ entry.displayname }} - Deinstallation</span>
                <span v-else-if="entry.type === 'sync'">{{ entry.displayname }} - Sync</span>
                <span v-else-if="entry.type === 'hostname'">
                  {{ entry.displayname }} - {{ entry.command_parameter }}
                </span>
                <span v-else>{{ entry.displayname }}</span>
              </b-col>
              <b-col class="text-right pr-1">
                {{ entry.time_execute_end | datetime }}
              </b-col>
              <b-tooltip :target="'tt-task-d-finished-' + entry.id" triggers="hover">
                Gesetzt von {{ entry.issuer }}
              </b-tooltip>
            </b-row>
            <b-row v-if="entry.status === 'error'" :id="'tt-task-d-error-' + entry.id"
                   class="tile-expand-task-error">
              <b-col class="text-left pl-1">
                <span v-if="entry.type === 'install'">{{ entry.displayname }} - Installation</span>
                <span v-else-if="entry.type === 'deinstall'">{{ entry.displayname }} - Deinstallation</span>
                <span v-else-if="entry.type === 'sync'">{{ entry.displayname }} - Sync</span>
                <span v-else-if="entry.type === 'hostname'">
                  {{ entry.displayname }} - {{ entry.command_parameter }}
                </span>
                <span v-else>{{ entry.displayname }}</span>
              </b-col>
              <b-col class="text-right pr-1">
                {{ entry.time_execute_end | datetime }}
              </b-col>
              <b-tooltip :target="'tt-task-d-error-' + entry.id" triggers="hover">
                Gesetzt von {{ entry.issuer }}
              </b-tooltip>
            </b-row>
          </div>
          <b-row>
            <router-link :to="{ name: 'TaskList', query: {site: shortname, hostname: hostname}}"
                         class="text-center clickable more-data expand-hover rounded mt-1 col router-text">
              Zeige alle {{ count }} Aktionen...
            </router-link>
          </b-row>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Daten vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import Tile from "@/components/details/Tile";

export default {
  name: "TileTasks",
  props: ['title', 'data', 'count', 'hostname'],
  components: {
    Tile
  },
  computed: {
    shortname() {
      return this.hostname.split('-')[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles.scss';
</style>
