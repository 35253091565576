<template>
  <b-modal :id="'licenseCommentModal-'+ modalId" :title="title" size="md" @ok="sendData" @hidden="reset" @show="onShow">
    <b-form v-if="form" @submit.stop.prevent="">
      <label>Kategorie: <span class="text-danger">*</span></label>
      <br>
      <b-form-select v-model="commentCategorySelect.selected" :options="commentCategorySelect.options"/>
      <br>
      <label class="mt-2">Zugänglichkeit: <span class="text-danger">*</span></label>
      <br>
      <b-form-select v-model="commentPrioritySelect.selected" :options="commentPrioritySelect.options"/>
      <br>
      <label class="mt-2">Nachricht: <span class="text-danger">*</span></label>
      <b-form-textarea v-model="form.message" style="min-height: 100px"/>
    </b-form>
    <label class="text-required"><span class="text-danger">*</span> Feld wird benötigt</label>
    <template #modal-footer="{ ok, cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok()">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
import {getRequest, putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "LicenseCommentModal",
  props: ['licenseId', 'modalId', 'comment', 'method'],
  data() {
    return {
      commentCategorySelect: {
        selected: null,
        options: []
      },
      commentPrioritySelect: {
        selected: null,
        options: []
      },
      form: {
        message: '',
      }
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    reset() {
      this.form = {
        message: '',
      }
      this.commentCategorySelect.selected = null
      this.commentPrioritySelect.selected = null
    },
    sendData() {
      if(this.method === 'update') {
        this.updateComment()
      }
      else {
        this.addComment()
      }
    },
    async onShow() {
      await this.getCommentCategories();
      await this.getCommentPriorities();
      if(this.comment && this.method === 'update') {
        this.commentCategorySelect.selected = this.comment.license_comment_category_id
        this.commentPrioritySelect.selected = this.comment.license_comment_priority_id
        this.form.message = this.comment.message
      }
      else {
        this.reset()
      }
    },
    async getCommentCategories() {
      this.commentCategorySelect.options = []
      await getRequest('licenses/comments/comment-categories', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.commentCategorySelect.options.push({value: element.id, text: element.displayname})
            })
          })
    },
    async getCommentPriorities() {
      this.commentPrioritySelect.options = []
      await getRequest('licenses/comments/comment-priorities', null, this)
          .then((response) => {
            response.data.forEach(element => {
              this.commentPrioritySelect.options.push({value: element.id, text: element.displayname})
            })
          })
    },
    async addComment() {
      let data = {
        license_install_id: this.licenseId,
        license_comment_category_id: this.commentCategorySelect.selected,
        license_comment_priority_id: this.commentPrioritySelect.selected,
        message: this.form.message,
      }
      await putRequest('licenses/comments/add', data, this, 'Neuer Kommentar wurde erfolgreich erstellt.', 'Neuer Kommentar konnte nicht erstellt werden.')
          .then(() => {
            this.refresh()
          })
    },
    async updateComment() {
      let data = {
        license_install_id: this.licenseId,
        license_comment_category_id: this.commentCategorySelect.selected,
        license_comment_priority_id: this.commentPrioritySelect.selected,
        message: this.form.message,
        comment_id: this.comment.id
      }
      await putRequest('licenses/comments/update', data, this, 'Kommentar wurde erfolgreich geändert.', 'Kommentar konnte nicht geändert werden.')
          .then(() => {
            this.refresh()
          })
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
    title() {
      if(this.method === 'update') {
        return 'Kommentar ändern'
      }
      return 'Kommentar hinzufügen'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
