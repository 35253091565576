<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body class="tile-expand-content p-0 py-2">
        <b-container fluid v-if="shortData && longData">
          <b-row v-for="(value, key) in shortData" :key="key">
            <b-col class="text-left text-bold">
              {{ key }}
            </b-col>
            <b-col class="text-right">
              <span v-if="value">{{ value }}</span>
              <span v-else>-</span>
            </b-col>
          </b-row>
          <TransitionExpand>
            <div v-if="expand">
              <b-row v-for="(value, key) in longData" :key="key">
                <b-col class="text-left text-bold">
                  {{ key }}
                </b-col>
                <b-col class="text-right">
                  <span v-if="value">{{ value }}</span>
                  <span v-else>-</span>
                </b-col>
              </b-row>
            </div>
          </TransitionExpand>
          <b-row>
            <b-col v-if="expand" class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
              <b-icon-chevron-up :variant="chevronVariant" class="clickable" scale="1.5"/>
            </b-col>
            <b-col v-else class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
              <b-icon-chevron-down :variant="chevronVariant" class="clickable" scale="1.5"/>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Daten vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import {mapGetters} from "vuex";
import Tile from "@/components/details/Tile";
import TransitionExpand from "@/components/utility/TransitionExpand";

export default {
  name: "TileHardware",
  props: ['title', 'data', 'singleView'],
  components: {
    TransitionExpand,
    Tile,
  },
  data() {
    return {
      expand: this.singleView
    }
  },
  methods: {
    showDetails() {
      this.expand = !this.expand;
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    chevronVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'light'
    },
    shortData() {
      if(this.data) {
        return {
          'Hersteller': this.data['factoryname'],
          'Bezeichnung': this.data['displayname'],
          'Festplatte': this.data['disk'],
          'Festplatte Größe': this.data['disk_size']
        }
      }
      return null;
    },
    longData() {
      if(this.data) {
        return {
          'CPU': this.data['cpu'],
          'GPU Intern': this.data['gpu_internal'],
          'GPU Extern': this.data['gpu_external'],
          'Arbeitsspeicher': this.data['ram'],
          'Arbeitsspeicher Größe': this.data['ram_size'],
          'Netzwerkkarte LAN': this.data['nic_lan'],
          'Netzwerkkarte WLAN': this.data['nic_wlan'],
        }
      }
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles.scss';
</style>
