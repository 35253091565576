<template>
  <b-tab>
    <template #title>
     Notiz <b-icon-info-circle v-if="computerDetails.computer.note" scale="0.8"/>
    </template>
    <b-container fluid class="p-0 mb-3">
      <b-form @submit.stop.prevent="">
        <b-form-textarea v-model="commentNote" placeholder="Notizfeld..." class="note-area"/>
      </b-form>
      <b-row class="mt-2">
        <b-col cols="auto">
          <b-button variant="primary" @click="saveNote">
            <b-spinner v-show="loadingSaveButton" small/>
            Speichern
          </b-button>
        </b-col>
        <b-col align-self="center" cols="auto" v-if="computerDetails.computer.note_user && computerDetails.computer.note_updated_at" class="text-secondary pl-0">
          geändert von {{ computerDetails.computer.note_user.username }} am {{ computerDetails.computer.note_updated_at | datetime }}
        </b-col>
      </b-row>
    </b-container>
  </b-tab>
</template>

<script>
import {putRequest} from "@/modules/requests";

export default {
  name: "NoteTab",
  props: ['pcUuid', 'computerDetails'],
  data() {
    return {
      loadingSaveButton: false,
      commentNote: this.computerDetails.computer.note
    }
  },
  methods: {
    async saveNote() {
      this.loadingSaveButton = true
      let data = {
        note: this.commentNote,
      }
      await putRequest('pc/note/' + this.pcUuid, data, this, 'Erfolgreich gespeichert!')
          .then(() => {
            this.loadingSaveButton = false
            this.$emit('refresh')
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Details.scss';
</style>
