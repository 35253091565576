<template>
  <b-row :class="[syncedPacket.progress < 100 ? 'entry-warning' : 'entry-primary']">
    <b-col class="text-left pl-1">
      <span>{{ syncedPacket.sync.displayname }}</span>
    </b-col>
    <b-col class="text-right pr-1">
      <span>{{ syncedPacket.progress }}%</span>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SyncedEntry",
  props: ['syncedPacket']
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Entries.scss';
</style>
