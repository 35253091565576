<template>
  <b-tab title="Detailansicht" active>
    <b-container fluid class="p-0 mb-3">
      <b-row v-if="rzpc">
        <b-col>
          <TileCustomer title="Kunde" :data="computer.details.customer" :singleView="singleView" class="mb-3"/>
          <TileCustomer title="Zugewiesener Kunde" :data="computer.details.customer_assigned" :singleView="singleView"/>
        </b-col>
        <b-col>
          <TileComputer title="Computer" :data="computer.details.computer" :singleView="singleView" class="mb-3"
                        @refresh="refresh"/>
          <TileSoftware title="Software" :data="computer.details.licenses" :hostname="computer.hostname"
                        @refresh="refresh"/>
        </b-col>
        <b-col>
          <TileProcesses title="Prozessfunde" :data="computer.details.processes" class="mb-3" @refresh="refresh"
                         :count="computer.details.processes_count" :standortData="standort"
                         :hostname="computer.hostname"/>
          <TileViruses title="Virenfunde" :data="computer.details.viruses" :count="computer.details.viruses_count"
                       :standortData="standort" :hostname="computer.hostname" @refresh="refresh"/>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <TileCustomer title="Kunde" class="mb-3" :data="computer.details.customer" :warning="customerWarning"
                        :singleView="singleView"/>
          <TileCustomer title="RPC-Kunde" class="mb-3" :data="computer.details.customer_rpc" :warning="customerWarning"
                        :singleView="singleView"/>
          <TileCustomer title="Zugewiesener Kunde" :data="computer.details.customer_assigned" :warning="customerWarning"
                        :singleView="singleView"/>
        </b-col>
        <b-col>
          <TileTasks title="Aktionshistorie" class="mb-3" :data="computer.details.tasks"
                     :count="computer.details.tasks_count" :hostname="computer.hostname"/>
          <TileSoftware title="Software" :data="computer.details.software" :hostname="computer.hostname"
                        @refresh="refresh"/>
        </b-col>
        <b-col>
          <TileComputer title="Computer" class="mb-3" :data="computer.details.computer" :singleView="singleView"
                        @refresh="refresh"/>
          <TileHardware title="Hardware" :data="computer.details.hardware" :singleView="singleView" class="mb-3"/>
          <TileProcesses title="Prozessfunde" class="mb-3" :data="computer.details.processes"
                         :hostname="computer.hostname" :count="computer.details.processes_count" @refresh="refresh"/>
          <TileViruses title="Virenfunde" :data="computer.details.viruses" :count="computer.details.viruses_count"
                       :hostname="computer.hostname" @refresh="refresh"/>
        </b-col>
      </b-row>
    </b-container>
  </b-tab>
</template>

<script>
import TileTasks from "@/components/details/computerDetails/tiles/TileTasks";
import TileViruses from "@/components/details/computerDetails/tiles/TileViruses";
import TileCustomer from "@/components/details/computerDetails/tiles/TileCustomer";
import TileComputer from "@/components/details/computerDetails/tiles/TileComputer";
import TileHardware from "@/components/details/computerDetails/tiles/TileHardware";
import TileSoftware from "@/components/details/computerDetails/tiles/TileSoftware";
import TileProcesses from "@/components/details/computerDetails/tiles/TileProcesses";

export default {
  name: "DetailsTab",
  props: ['computer', 'rzpc', 'singleView'],
  components: {
    TileCustomer,
    TileComputer,
    TileHardware,
    TileTasks,
    TileSoftware,
    TileProcesses,
    TileViruses,
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  },
  computed: {
    customerWarning() {
      if (this.computer.warnings) {
        if (this.computer.warnings.includes('customerDiff')) {
          return true
        }
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
