<template>
  <div v-if="synced">
    <div class="row text-bold">Syncs:</div>
    <div v-for="disk in synced" :key="disk.id" class="mt-1">
      <div v-if="synced.length > 1" class="mx-0">Festplatte: {{ disk.sn }}</div>
      <div v-for="syncedPacket in disk.syncs" :key="syncedPacket.id" class="mt-1">
        <SyncedEntry :syncedPacket="syncedPacket"/>
      </div>
    </div>
  </div>
</template>

<script>
import SyncedEntry from "@/components/details/computerDetails/entries/SyncedEntry";

export default {
  name: "SyncedPackets",
  props: ['synced'],
  components: {
    SyncedEntry
  }
}
</script>

<style lang="scss" scoped>
</style>
