<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body :class="[data.licenses || data.syncs ? ['tile-body', 'pb-2'] : ['tile-expand-content', 'py-2'], 'p-0']">
        <b-container fluid v-if="data.licenses || data.syncs">
          <div class="mt-1">
            <Licenses :licenses="data.licenses" :hostname="hostname" @comment="openModal"/>
            <b-row v-if="data.licenses">
              <router-link :to="{name: 'Licenses', query: {site: shortname, hostname: hostname}}"
                           class="text-center clickable more-data expand-hover rounded mt-1 col router-text">
                Zeige alle Lizenzen...
              </router-link>
            </b-row>
            <hr v-if="data.syncs && data.licenses" class="divider my-2 row"/>
            <SyncedPackets :synced="data.syncs"/>
          </div>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Daten vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
    <LicenseCommentModal v-if="data.licenses" :modalId="hostname" method="add" :licenseId="licenseId"
                         @refresh="refresh"/>
  </Tile>
</template>

<script>
import Tile from "@/components/details/Tile";
import LicenseCommentModal from "@/components/lists/licensesList/LicenseCommentModal.vue";
import Licenses from "@/components/details/computerDetails/tileSoftware/Licenses";
import SyncedPackets from "@/components/details/computerDetails/tileSoftware/SyncedPackets";

export default {
  name: "TileSoftware",
  props: ['title', 'data', 'hostname'],
  components: {
    Tile,
    Licenses,
    SyncedPackets,
    LicenseCommentModal
  },
  data() {
    return {
      licenseId: null
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    openModal(license) {
      this.licenseId = license.id
      this.$bvModal.show('licenseCommentModal-' + this.hostname)
    }
  },
  computed: {
    shortname() {
      return this.hostname.split('-')[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles.scss';

.divider {
  border-top: 0.2em solid $grey-500;
  border-radius: 25px;
}
</style>
