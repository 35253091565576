<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body class="tile-expand-content p-0 py-2">
        <b-container fluid v-if="shortData && longData">
          <b-row v-for="(value, key) in shortData" :key="key">
            <b-col class="text-left text-bold">
              {{ key }}
            </b-col>
            <b-col class="text-right">
              <span v-if="key === 'Zuletzt gemeldet' || key === 'Letzter Systemstart' || key === 'Zuletzt geimaged'">
                <span v-if="value">{{ value | datetime }}</span>
                <span v-else>-</span>
              </span>
              <span v-else-if="key === 'MAC-Adresse'">
                <Clipboard v-if="value" class="pr-0" :value="value" title="MAC-Adresse kopieren"/>
                <span v-else>-</span>
              </span>
              <span v-else-if="key === 'BIOS-Seriennummer'">
                <Clipboard v-if="value" class="pr-0" :value="value" title="Seriennummer kopieren"/>
                <span v-else>-</span>
              </span>
              <span v-else-if="key === 'Seriennummer'">
                <b-icon-pencil v-if="!value && !serialNumberDeviceEdit" class="badge-control clickable ml-1"
                               @click="serialNumberDeviceEdit = !serialNumberDeviceEdit"/>
                <span v-if="serialNumberDeviceEdit">
                  <input v-model="form.serialNumberDevice" type="text" class="tiny-input">
                  <b-icon-check2-circle class="badge-control clickable ml-1"
                                        @click="saveSerialNumberDevice"/>
                </span>
                <span v-else>
                  <span v-if="!serialNumberDeviceControl" class="badge-control clickable" title="Funktionen öffnen"
                        @click="serialNumberDeviceControl = true">
                  <span v-if="value">{{ value }}</span>
                  <span v-else></span>
                </span>
                <span v-else>
                  <span>
                  <ClipboardUtility :value="value" title="Seriennummer zus. kopieren"/>
                    <b-badge variant="primary" class="p-1 px-2 b-l b-r" title="Bearbeiten"
                             @click="editSerialNumberDevice">
                      <b-icon-pencil scale="1.4"/>
                    </b-badge>
                    <b-badge :variant="variantDanger" class="p-1 px-2 b-r" title="Schließen"
                             @click="serialNumberDeviceControl = false">
                      <b-icon-x scale="1.8"/>
                    </b-badge>
                  </span>
                </span>
                </span>
              </span>
              <span v-else>
                <span v-if="value">{{ value }}</span>
                <span v-else>-</span>
              </span>
            </b-col>
          </b-row>
          <TransitionExpand>
            <div v-if="expand">
              <b-row v-for="(value, key) in longData" :key="key">
                <b-col class="text-left text-bold">
                  {{ key }}
                </b-col>
                <b-col class="text-right">
                  <span
                      v-if="key === 'Zuletzt gemeldet' || key === 'Letzter Systemstart' || key === 'Zuletzt geimaged'">
                    <span v-if="value">{{ value | datetime }}</span>
                    <span v-else>-</span>
                  </span>
                  <span v-else-if="key === 'MAC-Adresse'">
                    <Clipboard v-if="value" :value="value" title="MAC-Adresse kopieren"/>
                    <span v-else>-</span>
                  </span>
                  <span v-else-if="key === 'Seriennummer'">
                    <Clipboard v-if="value" :value="value" title="Seriennummer kopieren"/>
                    <span v-else>-</span>
                  </span>
                  <span v-else-if="key === 'Monitore'">
                    <span v-if="value.length !== 0">
                      <span v-for="display in value" :key="display">
                        {{ display }} <br>
                      </span>
                    </span>
                    <span v-else>-</span>
                  </span>
                  <span v-else>
                    <span v-if="value">{{ value }}</span>
                    <span v-else>-</span>
                  </span>
                </b-col>
              </b-row>
            </div>
          </TransitionExpand>
          <b-row>
            <b-col v-if="expand" class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
              <b-icon-chevron-up :variant="chevronVariant" class="clickable" scale="1.5"/>
            </b-col>
            <b-col v-else class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
              <b-icon-chevron-down :variant="chevronVariant" class="clickable" scale="1.5"/>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Daten vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";
import Tile from "@/components/details/Tile";
import Clipboard from "@/components/utility/Clipboard";
import ClipboardUtility from "@/components/utility/ClipboardUtility";
import TransitionExpand from "@/components/utility/TransitionExpand";

export default {
  name: "TileComputer",
  props: ['title', 'data', 'singleView'],
  components: {
    Tile,
    Clipboard,
    ClipboardUtility,
    TransitionExpand
  },
  data() {
    return {
      expand: this.singleView,
      serialNumberDeviceControl: false,
      serialNumberDeviceEdit: false,
      form: {
        serialNumberDevice: ""
      }
    }
  },
  methods: {
    showDetails() {
      this.expand = !this.expand;
    },
    editSerialNumberDevice() {
      this.serialNumberDeviceEdit = true
      this.serialNumberDeviceControl = false
      this.form.serialNumberDevice = this.data['serial_number_device']
    },
    async saveSerialNumberDevice() {
      const data = {
        serial_number_device: this.form.serialNumberDevice
      }
      await putRequest('serial-number-device/' + this.data['pc_uuid'], data, this)
          .then(() => {
            this.$emit('refresh', true)
          })
      this.serialNumberDeviceEdit = false
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    chevronVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'light'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
    shortData() {
      if (this.data) {
        return {
          'BIOS-Seriennummer': this.data['serial_number'],
          'Seriennummer': this.data['serial_number_device'],
          'Zuletzt gemeldet': this.data['time_last_seen'],
          'Zuletzt geimaged': this.data['time_image_installed'],
          'MAC-Adresse': this.data['mac_address'],
        }
      }
      return null;
    },
    longData() {
      if (this.data) {
        return {
          'IPv4-Adresse': this.data['ipv4_address'],
          'IPv6-Adresse': this.data['ipv6_address'],
          'Hostname': this.data['hostname'],
          'Image': this.data['image'],
          'Dienstversion': this.data['service_version'],
          'Windowsversion': this.data['os_version'],
          'Settingsversion': this.data['settings_version'],
          'Festplattenauslastung (C:)': this.data['disk_c'],
          'Festplattenauslastung (D:)': this.data['disk_d'],
          'Akkustand': this.data['battery_usage'],
          'Monitore': this.data['displays'],
          'Letzter Systemstart': this.data['os_startup'],
        }
      }
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles.scss';
</style>
