<template>
  <div v-if="licenses">
    <div class="row text-bold">Lizenzen:</div>
    <div v-for="disk in licenses" :key="disk.id" class="mt-1">
      <div v-if="licenses.length > 1" class="mx-0">Festplatte: {{ disk.sn }}</div>
      <div v-for="license in disk.licenses" :key="license.id" class="mt-1">
        <LicenseEntry v-if="!license.allowed && (license.comment === 'none' || license.comment === 'noticed')"
                      :license="license" :hostname="hostname" :variant="variantDanger" @comment="comment"/>
        <LicenseEntry v-else-if="!license.allowed && (license.comment === 'commented' || license.comment === 'verified')"
                      :license="license" :hostname="hostname" :variant="variantWarning"/>
        <LicenseEntry v-else-if="license.allowed" :license="license" :hostname="hostname" :variant="variantSuccess"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LicenseEntry from "@/components/details/computerDetails/entries/LicenseEntry";

export default {
  name: "Licenses",
  props: ['licenses', 'hostname'],
  components: {
    LicenseEntry
  },
  methods: {
    comment(data) {
      this.$emit('comment', data)
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userColorAccessibility === 'red-green') { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
