<template>
  <b-card-body class="details-expand text-white pt-3 px-3 pb-2">
    <b-tabs class="tab-title" content-class="mt-3" justified>
      <DetailsTab :computer="computer" :rzpc="rzpc" :singleView="singleView" @refresh="refresh"/>
      <NoteTab :computerDetails="computer.details" :pcUuid="computer.pc_uuid" @refresh="refresh"/>
    </b-tabs>
  </b-card-body>
</template>

<script>
import NoteTab from "@/components/details/computerDetails/tabs/NoteTab";
import DetailsTab from "@/components/details/computerDetails/tabs/DetailsTab.vue";

export default {
  name: "ComputerDetails",
  props: ['computer', 'rzpc', 'singleView'],
  components: {
    NoteTab,
    DetailsTab
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  },
  computed: {
    customerWarning() {
      if (this.computer.warnings) {
        if (this.computer.warnings.includes('customerDiff')) {
          return true
        }
      }
      return false;
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/styles/Tabs.scss';
@import '../../../assets/styles/details/Details.scss';
</style>
